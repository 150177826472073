import axios from 'axios'

class AXIOSService {
  constructor() {
    this.axios = axios.create({
      baseURL: 'https://api.datamuse.com/'
    })
  }
  async init() {
  }

  async getRhyme(word) {
    return await this.axios.get(`/words?rel_rhy=${word}`)
  }

}

export default AXIOSService;