<template>
  <div class="editor">
   <div class="editor__header">
     <div class="editor__header-left">
     <i class="editor__header-item el-icon-back" @click="backToDashboard"></i>
     <i class="editor__header-item el-icon-refresh-left"></i>
     <i class="editor__header-item el-icon-video-play" @click="handleAudioManagerVisibility"></i>
     </div>
     <div class="editor__header-search">
       <el-input
         size="large"
         clearable
         placeholder="Search for a rhyme"
         >
       </el-input>
     </div>
     <div class="editor__header-right">
       <i class="editor__header-item el-icon-finished"></i>
       <i class="editor__header-item el-icon-share"></i>
       <i class="editor__header-item el-icon-user"></i>
     </div>
   </div>
    <div id="audio" class="editor__audio-manager">

    </div>
    <div class="editor__main">
      <textarea class="editor__text-area" id="textarea" :onmouseup="fetchHighlightedWord"></textarea>
      <div id="results-area" class="editor__main-results">
        <div v-for="result in results" :key="result">
          <div class="editor__main-single-result" @click="selectedWordToClipboard(result)">{{ result }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AXIOSService from "@service/index";
export default {
  name: "Editor",
  data() {
    return {
      isAudioManagerOpened: false,
      sampleSource: '@assets/samples/sample.mp3',
      highlightedText: '',
      results: [],
      xPos: '',
      yPos: ''
    }
  },
  methods:{
    selectedWordToClipboard(item) {
      navigator.clipboard.writeText(item)
      console.log('item : ', item);
    },
    showResultsArea() {
      const currentX = this.xPos + 200
      const currentY = this.yPos + 200

        const d = document.getElementById('results-area');
        d.style.position = "absolute";
        d.style.left = currentX + 'px';
        d.style.top = currentY + 'px';
        d.style.width = 200 + 'px'
        d.style.height = 400 + 'px'
      d.style.display = 'block'

    },
    fetchHighlightedWord(e) {
      const rect = e.target.getBoundingClientRect();
      this.xPos = e.clientX - rect.left; //x position within the element.
      this.yPos = e.clientY - rect.top;  //y position within the element.

        if (window.getSelection().toString().length) {
          let exactText = window.getSelection().toString();
          this.highlightedText = exactText;
          this.getRhymeList()
        }
        this.showResultsArea()
    },
     getRhymeList() {
      const service = new AXIOSService();
      service.getRhyme(this.highlightedText).then(res => {
        const resultsArr = res.data;
        if (this.results.length > 0) {
          this.results = [];
        }
        if (resultsArr && resultsArr.length >= 1) {
          for (let item of resultsArr) {
            this.results.push(item.word);
          }
        }
      })
    },
    backToDashboard() {
      this.$router.push({name : 'Dashboard'})
    },
    handleAudioManagerVisibility( ) {
      this.isAudioManagerOpened = !this.isAudioManagerOpened
      if(!this.isAudioManagerOpened) {
        document.getElementById('audio').classList.remove('slideDown')
        document.getElementById('audio').classList.add('slideUp')
      } else if (this.isAudioManagerOpened) {
        document.getElementById('audio').classList.remove('slideUp')
        document.getElementById('audio').classList.add('slideDown')
      }
    },


  }
};
</script>

<style lang="scss" scoped>
.editor{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: calc(100vw - 30px);
    background-color: #05445E;
    border-radius: 8px;
    margin: 30px;
    color: white;
    position: relative;
  }

  &__header-search{
    position: absolute;
    top: 40px;
    left: calc( 25% - 110px);
    width: 100%;
    max-width: 1162px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__header-left {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  &__header-right {
   display: flex;
    align-items: center;
  }

  &__header-item {
    display: flex;
    margin: 0 30px;
    border-radius: 50%;
    transform: scale(1.3);
    padding: 2px;
    border: 1px solid whitesmoke;
    cursor: pointer;
  }

  &__audio-manager {
    background-color: #D4F1F4;
    width: 100%;
    max-width: 1100px;
    margin: 10px 0 50px;
    padding: 30px;
    border-radius: 4px;
    text-align: center;
    color: #05445E;
    transition: all .3s ease-out;
    opacity: 0;
  }

  &__main{
    width: 100%;
    margin-bottom: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &__text-area{
    font-size: 24px;
    outline: none;
    padding: 30px 50px;
    background-color: #189AB4;
    color: #D4F1F4;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    max-height: 700px;
    max-width: 1060px;
  }

  &__main-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 450px;
    overflow: auto;
    color: #F6F7F8;
    background-color: #05445E;
    padding: 4px;
    display: none;

  }

  &__main-single-result {
    text-align: center;
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid #F6F7F8 ;
    cursor: pointer;

  }
}


.slideUp{
  visibility: hidden;
  opacity: 0;
  animation-name: slideUp;
  -webkit-animation-name: slideUp;

  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
}

.slideDown{
  opacity: 1;
  display: block;
  animation-name: slideDown;
  -webkit-animation-name: slideDown;

  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}

@keyframes slideUp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-80%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(-80%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-80%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-80%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}

</style>
